<script>
import { wpService } from "@/services/wp";
export default {
  props: {
    org_id: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      offices: null,
    };
  },

  methods: {
    query_offices() {
      wpService.organizations.queryOrgOffices(this.org_id).then(offices => {
        this.offices = offices;
      });
    },
  },

  mounted() {
    this.query_offices();
  },
};
</script>

<template>
  <div class="offices__outer">
    <appLoading v-if="!offices" />
    <div v-else-if="!offices.entries.length" class="no_offices">
      <p>Esta organización no ha publicado ninguna oficina.</p>
    </div>
    <div v-else class="offices__inner">
      <div
        class="member__outer"
        v-for="(entry, index) in offices.entries"
        :key="index"
      >
        <div class="member__inner">
          <div class="user_avatar">
            <img v-if="!entry.user_avatar" src="@/assets/avatar-square.svg" />
            <img
              v-else
              :src="entry.user_avatar.src"
              :srcset="entry.user_avatar.srcset"
            />
          </div>
          <div class="user_info">
            <div class="user_name">
              {{ entry.user_name_full }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offices__outer {
  position: relative;
}
img {
  width: 100%;
}
.offices__inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  .user_name {
    font-size: 0.875rem;
    font-weight: 600;
    color: #111;
    margin-top: 4%;
  }
  .user_avatar {
    border-radius: 3px;
    overflow: hidden;
    img {
      display: block;
    }
  }
  @media (min-width: 414px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3vw;
  }
  @media (min-width: 640px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 860px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 1080px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 1220px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 2rem;
  }
}
</style>
